export default {
  black: '#000000de',
  gray: '#989898',
  red: '#E7302A',
  white: '#FFF',
  green: '#16A02A',
  orange: '#ffd3b6',
  purple: '#8e2e8c',
  yellow: '#F7DE00',

  // Custom
  lightPurple: '#561F63',
  darkPurple: '#26224E',
  darkBlue: '#26224E',

  // Theme colors
  facebook: '#4267B2',
  twitter: '#1DA1F2',
}