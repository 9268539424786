import colors from '~/styles/colors'

export const zIndexes = {
  overlay: 100,
  top: 10,
  middle: 5,
  bottom: 0,
}

export const HEADER_HEIGHT = '10rem'

export const FOOTER_HEIGHT = '10rem'

export const MAX_SCREEN_WIDTH = '90rem'

export const MAX_BODY_WIDTH = '70rem'

export const boxShadow = `box-shadow: 0rem 0rem 1rem 0.2rem ${colors.white};`

export const dropShadow = `filter: drop-shadow(0rem 0rem 1rem ${colors.white});`

export const transition = 'transition: 0.2s ease-in-out;'